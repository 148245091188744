import { Site } from "@libry-content/types";
import { groq } from "next-sanity";
import { HeaderFooterData, resolveHeaderFooterData } from "../components/layout/sanityQuery";
import { sanityClient } from "./sanity/client";

// TODO reduce amount of data fetched here
export const resolveSiteQuery = groq`...`;

export type ResolvedSite = Site;

const commonDataQuery = groq`
  "site": {${resolveSiteQuery}},
  "headerFooterData": ${resolveHeaderFooterData},
  "bookListStyling": {
    'bookListIds': *[_type == "list"] | order(_createdAt desc)[]._id,
  }
`;

export type CommonData = {
  site?: ResolvedSite;
  headerFooterData?: HeaderFooterData;
  // TODO Extract this so it's only fetched on pages that needs it?
  bookListStyling?: {
    bookListIds: string[];
  };
};

export type WithCommonData<Data> = Data & { commonData: CommonData };

export const scopeQueryToSite = (query: string) => {
  if (!query.match(/\* *\[/)) console.warn("Tried to scope query without filter (*[]) to site");

  return (
    query
      // Add site scope:
      // Ensure all queries are scoped to the current site
      // Using "site._ref == ^._id" instead of "site->domain == $siteDomain" doubles speed of queries https://www.sanity.io/docs/high-performance-groq#68c7065755fd
      // This means the enitre query must be wrapped with a site-query so "^" refers to the current site
      .replace(/\* *\[/g, "*[ site._ref == ^._id && ")
      // Global queries are not be scoped to site
      .replace(/GLOBAL\[/g, "*[")
  );
};

/**
 * @deprecated use pageDataFetcher in combination with PageWrapper instead
 *
 * Fetch results from a GROQ query, rewritten to be scoped to documents from the current site ($siteDomain) and
 * extended with common data needed by header/footer and other common functionality.
 * Returns an object that contains the data and the information needed for preview-functionality to work.
 *
 * @param query GROQ query
 * @param queryParams GROQ query parameters, including `siteDomain` in addition to the parameters in the query
 */
export const fetchWithCommonDataAndCurrentSiteFilter = async <
  Props extends WithPreviewConfigAndCommonSiteData = WithPreviewConfigAndCommonSiteData
>(
  query: string,
  queryParams: Props["queryParams"]
) => {
  const queryWithCommonData =
    // Add site scope: Wrapping entire query with a site query so "^._id" refers to the sites _id in nested filters
    groq`*[_type == "site" && domain == $siteDomain][0]` +
    query
      // Add commondata: Add data about the current site needed by layout elements (header, footer, etc.)
      .replace(/^{/, `{ "commonData": {${commonDataQuery}},`);

  const queryWithCommonDataAndSiteScope = scopeQueryToSite(queryWithCommonData);

  const data: Props["data"] = await sanityClient.fetch(queryWithCommonDataAndSiteScope, queryParams);

  return { data, query: queryWithCommonDataAndSiteScope, queryParams };
};

export interface WithPreviewConfigAndCommonSiteData<ResponseData = {}, QueryParams = {}> {
  data: WithCommonData<ResponseData>;
  query: string;
  queryParams: QueryParams & {
    siteDomain: string;
  };
}
