import { colors } from "@biblioteksentralen/js-utils";
import { CustomPalette } from "@libry-content/types";
import { useCommonData } from "../components/layout/CommonDataProvider";
import { isBright } from "./colors";
import { ResolvedSite } from "./fetchWithCommonDataAndCurrentSiteFilter";
import { useFeatureToggle } from "./hooks/featureToggles";

type ColorWithTextColor = {
  css: {
    background: string;
    color: string;
  };
  backgroundIsBright: boolean;
};

export type ColorPaletteKeys = keyof Omit<CustomPalette, "_type" | "useCustomPalette">;

export type ColorPalette = { useCustomPalette: boolean; colors: Record<ColorPaletteKeys, ColorWithTextColor> };

const defaultColors: Record<ColorPaletteKeys, string> = {
  header: "#ffffff",
  footer: colors.black,
  accent1: colors.accentBlueDark,
  accent2: colors.accentGreen,
  accent3: colors.accentPeach,
  accent4: colors.accentPurple,
  accent5: colors.lightGreen,
  accent6: colors.lightPeach,
  accent7: colors.accentBlueMedium,
};

const colorPaletteKeys = Object.keys(defaultColors) as ColorPaletteKeys[];

export const useSitePalette = (): ColorPalette => {
  const featureToggle = useFeatureToggle("custom-palette");
  const { site } = useCommonData();
  const useCustomPalette = featureToggle && !!site?.customPalette?.useCustomPalette;

  const colors = colorPaletteKeys.reduce(
    (acc, key) => ({ ...acc, [key]: getColorFor(key, useCustomPalette, site) }),
    {} as Record<ColorPaletteKeys, ColorWithTextColor>
  );

  return {
    colors,
    useCustomPalette,
  };
};

const getColorFor = (key: ColorPaletteKeys, useCustomPalette: boolean, site?: ResolvedSite): ColorWithTextColor => {
  const color = (useCustomPalette && site?.customPalette?.[key]?.color) || defaultColors[key];

  const backgroundIsBright = isBright(color);

  return {
    css: {
      background: color,
      color: backgroundIsBright ? "gray.800" : "white",
    },
    backgroundIsBright,
  };
};
