import {
  DigitalLibrary,
  DigitalLibraryService,
  DigitalLibraryServiceCustom,
  DigitalLibraryServiceTemplate,
  DigitalLibraryServiceTemplateContent,
  Modify,
} from "@libry-content/types";
import { groq } from "next-sanity";

export const digitalLibraryFilter = groq`_type == "digitalLibrary" && count(services[defined(_type)]) == 1`;

const digitalLibraryGlobalContent = groq`select(
  @._type == "digitalLibraryServiceCustom" => null,
  GLOBAL[_type == ^._type + "Content"][0]
)`;

export const resolveConciseDigitalLibraryService = groq`
  _id,
  ...services[defined(_type)][0] {
    _type,
    slug,
    digitalLibraryServiceCategory,
    name,
    teaser,
    ...${digitalLibraryGlobalContent}{name, teaser},
  }
`;

export type ResolvedConciseDigitalLibraryService = Pick<DigitalLibrary, "_id"> &
  (
    | Pick<DigitalLibraryServiceCustom, "_type" | "digitalLibraryServiceCategory" | "name" | "teaser">
    | (Pick<DigitalLibraryServiceTemplate, "_type" | "slug" | "digitalLibraryServiceCategory"> &
        Pick<DigitalLibraryServiceTemplateContent, "name" | "teaser">)
  );

export const resolveDigitalLibraryService = groq`
  ...,
  "services": null,
  ...services[defined(_type)][0] {
    ...,
    ...${digitalLibraryGlobalContent}{name, teaser, description, extraFields, getStarted, support, digitalLibraryServiceSupport},
    // These are defined as strings in schema in order to get the right studio layout
    "canLogInWithFeide": select(canLogInWithFeide == "true" => true, false),
    "canBeUsedWithSelfService": select(canBeUsedWithSelfService == "true" => true, false)
  }
`;

export type ResolvedDigitalLibraryService = Omit<DigitalLibrary, "_type" | "services"> &
  Modify<
    DigitalLibraryService,
    {
      canLogInWithFeide: boolean;
      canBeUsedWithSelfService: boolean;
    }
  > &
  Pick<
    DigitalLibraryServiceTemplateContent,
    "name" | "teaser" | "description" | "extraFields" | "getStarted" | "digitalLibraryServiceSupport"
  >;
