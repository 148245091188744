import { getSiteUrl, imageUrlBuilder, splashSiteUrl, withoutUndefinedValues } from "@libry-content/common";
import { withErrorBoundary } from "./errorPages/withErrorBoundary";
import { DecorativeImage, ImageWithMetadata, Municipality } from "@libry-content/types";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useReducer } from "react";
import type { Thing } from "schema-dts";
import { useTranslation } from "../utils/hooks/useTranslation";
import { loggError } from "../utils/logging";
import { removeTrailingSlash } from "../utils/removeTrailingSlash";
import { useCommonData } from "./layout/CommonDataProvider";
import { getSchemaOrgImageUrl } from "./library/utils";
import { getSchemaOrgSiteImage } from "./site/utils";
import { ResolvedSite } from "../utils/fetchWithCommonDataAndCurrentSiteFilter";
import { usePreviewContext } from "./preview/previewContext";
import uniqBy from "lodash/uniqBy";

export interface SEOProps {
  title: string;
  description: string;
  path: string;
  externalImageUrl?: string;
  sanityImage?: DecorativeImage | ImageWithMetadata;
  icon?: string;
  schemaOrgThing?: Omit<Thing, string> | Omit<Thing, string>[];
  disableImageCrop?: boolean;
}

export function getTitle(title: string, siteName?: string): string {
  if (!siteName) return title;
  if (!title.length) return siteName;
  if (title === siteName) return title;
  return `${title} | ${siteName}`;
}

const addDefaults = (schemaOrgThing: Omit<Thing, string>, title: string, description: string, imageUrl: string) => ({
  "@context": "https://schema.org/",
  name: title,
  description,
  image: imageUrl,
  ...withoutUndefinedValues(schemaOrgThing),
});

const addThingDefaults = (
  thing: NonNullable<SEOProps["schemaOrgThing"]>,
  ...defaults: [string, string, string]
): NonNullable<SEOProps["schemaOrgThing"]> =>
  Array.isArray(thing) ? thing.map((item) => addDefaults(item, ...defaults)) : addDefaults(thing, ...defaults);

function SEO(props: SEOProps) {
  const { ts } = useTranslation();
  const { site } = useCommonData();

  useReplaceBadUrl(props);

  const siteImg = getSchemaOrgSiteImage(site);
  const iconUrl = siteImg ? getSchemaOrgImageUrl(siteImg) : undefined;

  const imageUrl = props.externalImageUrl ?? getImageUrl(props.sanityImage, siteImg, props.disableImageCrop);

  const schemaOrgData = props.schemaOrgThing
    ? addThingDefaults(props.schemaOrgThing, props.title, props.description, imageUrl)
    : undefined;

  useEffect(() => {
    if (!/^\//.test(props.path)) {
      loggError(new Error("path må starte med /"));
    }
  }, [props.path]);

  const canonical = removeTrailingSlash(`${site ? getSiteUrl(site) : splashSiteUrl}${props.path}`);
  const siteName = ts(site?.name);
  const title = getTitle(props.title, siteName);

  return (
    <Head>
      <title>{title}</title>
      <link rel="icon" type="image/png" href={props.icon ?? iconUrl} />
      <link rel="canonical" href={canonical} />
      <meta name="description" content={props.description} />
      <meta property="image" content={imageUrl} />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={canonical} />
      <meta property="og:description" content={props.description} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:image" itemProp="image" content={imageUrl} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={props.description} />
      <meta name="twitter:image" content={imageUrl} />

      {schemaOrgData && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaOrgData, null, 2) }}
        />
      )}
    </Head>
  );
}

const seoImageheight = 630;
const seoImageWidth = 1200;

/**
 *
 * @param disableCrop Facebook handles both tall and wide images by autoplacing them left or above,
 * but this is not the case in general (example: LinkedIn, which also uses open graph). Therefore we
 * add space on the sides when not cropping, to preserve format.
 */
function getImageUrl(
  image: SEOProps["sanityImage"],
  fallbackImage?: ResolvedSite["logo"] | Municipality["coatOfArms"],
  disableCrop?: boolean
) {
  const withFallbackImage = !image?.asset;

  let urlBuilder = imageUrlBuilder(withFallbackImage ? fallbackImage : image)
    ?.quality(85)
    .bg("eee") // Use a background that approximates the background gradient we have in SanityImage
    .format("jpg");

  if (disableCrop) {
    // Without ignoreImageParams the builder adds rect() when both height and width are specified
    urlBuilder = urlBuilder?.ignoreImageParams().size(seoImageWidth, seoImageheight).fit("fill");
  } else {
    urlBuilder = urlBuilder?.size(seoImageWidth, seoImageheight);
  }

  // Fallback-image using logo or coatOfArms, but padded so it can handle some cropping
  if (withFallbackImage) urlBuilder = urlBuilder?.ignoreImageParams().fit("fill").pad(60);

  return urlBuilder?.url() ?? "";
}

type RedirectPaths = { expected: string; actual: string | undefined; params: string | undefined };

const maxRedirects = 3;

/**
 * Plasserer denne hooken i SEO. Konseputelt føles det ut som det hører hjemme under SEO siden det handler om å ha korrekt url, og fordi vi her har tilgjengelig hvilken path som regnes "som riktig" (cannonical). I tillegg trenger vi da bare å løse dette ett sted (gitt at alle pages bruker SEO-komponenten), istedenfor feks rundt i alle getStaticProps for hver enkelt side
 * Mange url'er inneholder en human-readable del som kan endre seg. Oppdaterer derfor url'en hvis man kommer inn med en utdatert url, slik at den blir riktig hvis man feks deler lenken. Man kommer til riktig side likevel pga id'en.
 */
const useReplaceBadUrl = (props: SEOProps) => {
  const router = useRouter();
  const previewContext = usePreviewContext();
  const [lastThreeRedirects, addRedirectAttempt] = useReducer(
    (state: RedirectPaths[], paths: RedirectPaths) => [...state, paths].slice(-maxRedirects),
    []
  );

  useEffect(() => {
    const expectedPath = props.path;
    const [actualPath, urlParams] = router.asPath.split("?"); // Ignorerer query-params så feks "/anbefaling" matcher "/anbefaling?preview=true"

    const pathIsCorrect = actualPath === expectedPath;

    if (!router.isReady) return;
    if (pathIsCorrect) return;
    if (previewContext.isOn !== false) return;

    const redirectedThreeTimes =
      lastThreeRedirects.length >= maxRedirects &&
      lastThreeRedirects.every(({ expected }) => expected === expectedPath);

    // Prevents accidential loop. Might be scenarios where we have a bug or for some reason are not able to fix the bad url for some reason. This should not loop/freeze the app.
    if (redirectedThreeTimes) {
      const attempts = uniqBy(
        lastThreeRedirects.map(({ actual, params }) => ({ actual, params })),
        "actual"
      );
      console.error(`Tried to redirect three times without success`, { expectedPath, attempts });
      return;
    }

    const newPath = `${expectedPath}${urlParams ? `?${urlParams}` : ""}`;

    setTimeout(() => {
      router.replace(newPath, undefined, { shallow: true }); // shallow: true gjør at man ikke gjør en full reload
      addRedirectAttempt({ expected: expectedPath, actual: actualPath, params: urlParams });
      console.log("Url did not match expected path. Replacing url.", { actualPath, expectedPath });
    }, 1000);
  }, [router, props.path, lastThreeRedirects, previewContext.isOn]);
};

export default withErrorBoundary(SEO, "SEO");
